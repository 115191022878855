import React from "react";
import { graphql } from "gatsby";
import { Grid, Image, List, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

const CollectionItem = ({ name, image, catalog, details, ctaUrl }) => (
  <>
    <h1>{name}</h1>
    <Grid stackable verticalAlign={"middle"} textAlign={"center"} columns="2">
      <Grid.Row>
        <Grid.Column
          width={6}
          tablet="16"
          computer="6"
          widescreen="6"
          largeScreen="6"
        >
          <Image src={image} size="medium" centered bordered rounded />
        </Grid.Column>
        <Grid.Column
          width={8}
          textAlign="center"
          tablet="16"
          computer="8"
          widescreen="8"
          largeScreen="8"
        >
          <div className="mobile">
            <h4 style={{ margin: "0" }}>{`${name} Collection`}</h4>
            <List
              horizontal
              size="large"
              items={catalog}
              style={{ margin: "0" }}
            />
            <div style={{ margin: "2em 0 1em 0" }}>
              <List items={details} />
            </div>
            <Button primary as="a" href={ctaUrl} target="_blank">
              SHOP NOW
            </Button>
          </div>
          <div className="desktop">
            <List items={details} />
            <div style={{ margin: "2em 0 1em 0" }}>
              <h4 style={{ margin: "0" }}>{`${name} Collection`}</h4>
              <List
                bulleted
                horizontal
                size="large"
                items={catalog}
                style={{ margin: "0" }}
              />
            </div>
            <Button primary as="a" href={ctaUrl} target="_blank">
              SHOP NOW
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Collections</title>
          <meta name="description" content={""} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Collections"}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Coastal Carolina"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Coastal_Carolina_main_pic.jpg"
                }
                catalog={[
                  "Blue delphinium light or dark",
                  "Blue hydrangeas",
                  "Thistle",
                  "Italian Ruscus",
                  "Babies breath",
                  "White spray roses",
                  "Bonsai eucalyptus",
                ]}
                details={[
                  "Pick any 4 $150 for bride",
                  "Pick them all $250.00 for bride",
                  "Bridesmaid $75.00 for any 4",
                  "Pick them all $95.00",
                  "Corsage pin on $25.00 each",
                  "Boutineere $12.00 each",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Coastal-Carolina-package-c67775534"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Free Spirit"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Free_spirit_main_pic.jpg"
                }
                catalog={[
                  "Queen Anne lace",
                  "Seeded eucalyptus",
                  "Amnesia roses",
                  "Thistle",
                  "Burgundy carnations",
                  "Burgundy amaranth",
                  "Willow eucalyptus",
                  "Silver dollar eucalyptus",
                  "White spray roses",
                  "protea",
                  "Protea foliage",
                ]}
                details={[
                  "Any 3 $90.00",
                  "Any 4 $145.00",
                  "Pick all $250.00",
                  "Bout $15.00",
                  "Corsage $30.00 pin on",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Free-Spirit-Collection-c67757597"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Downtown Lights"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Downtown_light_main_pic_.jpg"
                }
                catalog={[
                  "White ranunculus",
                  "White stock flower",
                  "White roses",
                  "Ruscus",
                  "Italian Ruscus",
                  "Seeded eucalyptus",
                  "Blush spray rose or white",
                  "White lisanthus",
                ]}
                details={[
                  "Any 3. $90 bride bridesmaids $75",
                  "Any 4 $125.00 bm $85",
                  "All $200.00 $125 bm",
                  "Bout $12.00",
                  "Corsage $25.00",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Downtown-Lights-Collection-c67774630"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Countryside"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Countryside_main_pic_.jpg"
                }
                catalog={[
                  "White roses",
                  "Sunflowers",
                  "Babies breath",
                  "Bunny tail",
                  "Seeded eucalyptus",
                  "Silver dollar eucalyptus",
                  "Bonsai eucalyptus",
                  "Purple stock (white and cream)",
                  "Blue delphinium",
                  "Feather fern",
                  "Spray roses (yellow,peach,blush or white)",
                ]}
                details={[
                  "Any 4 $90. bride. bridesmaids $50.00",
                  "Any 5 $150.00 bride. bridesmaids $75.00",
                  "Pick all $275.00 bride bridesmaids $125.00",
                  "Corsage $25",
                  "Bout $12.00",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Countryside-Collection-c67772513"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Sunset"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Sunset_main_pic.jpg"
                }
                catalog={[
                  "red rose",
                  "Yellow or orange alstroemeria",
                  "Circus rose",
                  "Green or red hypericum berries",
                  "Vendella roses cream",
                  "Thistle",
                  "Orange spray roses",
                  "Burgundy pixie carnations",
                  "Orange rose",
                  "Flame callas",
                ]}
                details={[
                  "Any 4 $135.bride $75.00 bridesmaids",
                  "Any 5 $175.00 bride $90.00 bridesmaids",
                  "All $275.00 bride $125.00 bridesmaids",
                  "Corsage $25.00",
                  "Bout $12.00",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Sunset-Collection-c67762612"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Timeless Classic"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Classic_collection_-2.jpg"
                }
                catalog={[
                  "Red roses",
                  "White roses",
                  "Italian ruscus",
                  "Israeli ruscus",
                  "White dendrobium orchids",
                  "White mini callas",
                  "White hydrangeas",
                  "White carnations",
                  "Babies breath",
                  "White ranunculus",
                  "White or red spray roses",
                ]}
                details={[
                  "Any 4 $135.00 bride $75.00 bm",
                  "Any 5 $200.00 bm. $90.",
                  "All $325. Bride $125.00 bridesmaids",
                  "Corsage $30",
                  "Bout $10",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Timeless-Classic-Collection-c67769608"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Colorful Jewels"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Colorful_jewels.jpg"
                }
                catalog={[
                  "Orange spray roses",
                  "Burgundy hearts roses",
                  "Thistle",
                  "Blue privet berry",
                  "Italian ruscus",
                  "Butterscotch mums",
                  "Orange tulips",
                  "Hot pink roses",
                  "Italian ruscus",
                  "Israeli ruscus",
                  "Feather fern",
                  "Burgundy astilbe",
                ]}
                details={[
                  "Pick any 4 $150 bm $75",
                  "Pick any 5 $225 bm $90",
                  "All $300 bm $150",
                  "Bout $12",
                  "Corsage $35",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Colorful-Jewels-c67774629"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Tropical Chic"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Tropical_collection_.jpg"
                }
                catalog={[
                  "Pink ginger",
                  "White anthrium",
                  "White or pink roses",
                  "White dendrobium orchids",
                  "Monstera leaves",
                  "Palm leaves",
                ]}
                details={[
                  "Any 4 $150bride $bm$90",
                  "Any 5 $200 bm $15",
                  "All $350 bm $200",
                  "Bout $20",
                  "Corsage $35",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Tropical-Chic-c67774628"
              />
            </Grid.Column>
          </Grid>

          <Grid stackable className="component-section-container">
            <Grid.Column width="16">
              <CollectionItem
                name="Passionate About Purple"
                image={
                  "https://fisherman.gumlet.io/public/albemarleweddings/Purple_main_pic.jpg"
                }
                catalog={[
                  "Lavender or White roses",
                  "White or purple stock flower",
                  "Purple or Lavender lisanthus",
                  "Purple or white dendrobium orchids",
                  "Seeded eucalyptus",
                  "Veronica",
                  "Italian ruscus",
                  "White mums",
                ]}
                details={[
                  "Any 3 $150 bm $75",
                  "Any4 $175 bm $90",
                  "All $250 bride bm $125",
                  "Corsage $30.",
                  "Bout $12",
                ]}
                ctaUrl="https://albemarleweddings.ecwid.com/Passionate-About-Purple-Collection-c67758559"
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
        }
      }
    }
    fishermanWebsitePage(pageType: { eq: "Custom" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
